






























import { computed, defineComponent, Ref, ref } from '@vue/composition-api'
import KBaseInput from './KBaseInput.vue'
import useInputRules from "@/@core/helpers/inputRules"
import useInputStyles from '@/@core/styles/inputStyles'

export default defineComponent({
    components: { KBaseInput },
    name: 'KPasswordInput',
    props: {
        showProgress: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { attrs }) {
        const { label, rules, ...filteredAttrs } = attrs

        const formattedRules: Ref<any[]> = computed(() => rules ? useInputRules(rules as any[], attrs.value) : [])
        
        const showPassword = ref(false)

        const color = computed(() => ['error', 'warning', 'success'][Math.floor(progress.value / 40)])

        const passwordStrength = computed(() => {
            let arrayValidation: number[] = []

            formattedRules.value.map((validation: Function, index: number) => {
                const isValid = validation() === true
                
                isValid ? arrayValidation[index] = 1 : arrayValidation[index] = 0
            })

            let validCounter = 0

            arrayValidation.map(valid => validCounter += valid)

            return validCounter
        })

        const progress = computed(() => {
            const multiplier = 100 / formattedRules.value.length
            const value = (<any>attrs).value ? passwordStrength.value * multiplier : 0

            return Math.min(100, value)
        })

        return {
            useInputStyles,
            filteredAttrs,
            formattedRules,
            showPassword,
            progress,
            color,
        }
    }
})
